import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, { class: "mt-2 font-size-12" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.frontendVersion), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "6",
            class: "text-right"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.frontendTime), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, { class: "font-size-12" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.backendVersion), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "6",
            class: "text-right"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.backendTime), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}