
import { Options, Vue, Watch } from "vue-property-decorator";
import Avatar from "@/components/Avatars/Avatar.vue";
import UserService from "@/services/UserService";

@Options({
  components: { Avatar },
})
export default class NewsAvatar extends Vue {
  private userId = UserService.getUser().id;
  private userName = UserService.getUser().firstName;
  private showAntrag = false;

  @Watch("$route", { immediate: true, deep: true })
  private onRouteChange(newVal: any): void {
    this.showAntrag = "Appointments" === this.$route.name;
  }
}
