
import { Options, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import StudentDocumentsStoreMixin from "@/mixins/StudentDocumentsStoreMixin.ts";
import { namespace } from "s-vuex-class";

const BroadcastModule = namespace("broadcast");

@Options({
  components: {},
})
export default class Navigation extends mixins(StudentDocumentsStoreMixin) {
  @Prop() private msg!: string;

  @BroadcastModule.Getter("getUnseenCount")
  public getUnseenCount!: number;
}
