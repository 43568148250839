import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarAndLogout = _resolveComponent("AvatarAndLogout")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, { class: "d-none d-xl-block mt-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_col, { cols: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_AvatarAndLogout, { class: "justify-content-end" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}