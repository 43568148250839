import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultHeader = _resolveComponent("DefaultHeader")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_TopBarDesk = _resolveComponent("TopBarDesk")!
  const _component_NewsBar = _resolveComponent("NewsBar")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: _normalizeClass(["ion-page", { 'safe-area': _ctx.safeArea }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header", {}, () => [
        _createVNode(_component_DefaultHeader, {
          showBackButton: _ctx.showBackButton,
          customBackButtonFunc: _ctx.customBackButtonFunc,
          useCustomFunc: _ctx.useCustomFunc
        }, null, 8, ["showBackButton", "customBackButtonFunc", "useCustomFunc"])
      ]),
      _createVNode(_component_ion_content, { "scroll-y": _ctx.scrollY }, {
        default: _withCtx(() => [
          (!_ctx.isDesktop)
            ? (_openBlock(), _createBlock(_component_ion_refresher, {
                key: 0,
                slot: "fixed",
                onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doRefresh($event)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_refresher_content)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(), _createElementBlock("div", {
            class: "container-fluid",
            key: _ctx.refreshKey
          }, [
            _createVNode(_component_TopBarDesk),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, {
                  xl: "2",
                  class: "d-none d-xl-block"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NewsBar)
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, {
                  cols: "12",
                  xl: "10"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, {
                          cols: "12",
                          class: "d-none d-xl-block mb-5"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Navigation)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_b_col, {
                          cols: "12",
                          class: _normalizeClass(_ctx.contentClass)
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "default")
                          ]),
                          _: 3
                        }, 8, ["class"])
                      ]),
                      _: 3
                    })
                  ]),
                  _: 3
                })
              ]),
              _: 3
            })
          ]))
        ]),
        _: 3
      }, 8, ["scroll-y"])
    ]),
    _: 3
  }, 8, ["class"]))
}