
import { Options, Vue } from "vue-property-decorator";
import { IonRow, IonCol } from "@ionic/vue";
import AvatarAndLogout from "@/components/Layout/Top/AvatarAndLogout.vue";

@Options({
  components: {
    AvatarAndLogout,
    IonRow,
    IonCol,
  },
})
export default class TopBarDesk extends Vue {
  public name = "TopBarDesk";
}
