import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center" }
const _hoisted_2 = { class: "font-size-22 font-weight-semibold mt-1 font-color-fsc-text-gray" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Avatar, { "user-id": _ctx.userId }, null, 8, ["user-id"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sentences.hello_user", { user: _ctx.userName })), 1)
  ]))
}