
import { Options, Vue } from "vue-property-decorator";
// import News from "@/components/Layout/NewsBar/News.vue";
import Survey from "@/components/Layout/NewsBar/Survey.vue";
import Version from "@/components/Layout/NewsBar/Version.vue";
import NewsAvatar from "@/components/Layout/NewsBar/NewsAvatar.vue";
import { IBroadcast } from "@/interfaces/IBroadcast";
import { namespace } from "s-vuex-class";

const BroadcastModule = namespace("broadcast");

@Options({
  components: { Version, Survey, NewsAvatar },
})
export default class NewsBar extends Vue {
  @BroadcastModule.Getter("list/getDataList")
  public broadcasts!: Array<IBroadcast>;

  @BroadcastModule.Getter("list/getIsLoading")
  public broadcastsIsLoading!: boolean;
}
